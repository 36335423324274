@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter', sans-serif;
}

:root, [data-amplify-theme] {
  --amplify-components-tabs-item-active-color: #ee6678;
  --amplify-components-tabs-item-active-border-color: #ee6678;
  --amplify-components-authenticator-router-border-color: rgba(0, 0, 0, 0.1);
}
.amplify-tabs-item:hover, .amplify-tabs-item:active, .amplify-tabs-item:focus {
  color: #ee6678;
}
.amplify-button--primary {
  background-color: #ee6678;
  border-color: #ee6678;
}
.amplify-button--primary:hover {
  background-color: #991023;
}
.amplify-button--link:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color:rgba(0, 0, 0, 0.9);
}
.amplify-button--link {
  color:rgba(0, 0, 0, 0.7)
}

[data-amplify-container] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

[data-amplify-router] {
  margin: 10px 0;
  width: 100%;
  max-width: 400px;
}

